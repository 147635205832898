import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { usePiniaStore } from "../store";
import { useAuth0 } from "@auth0/auth0-vue";
import { routeNames } from "@/constants/routeNames";

const mainRoutes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Default",
        component: () => import("../../views/default/NotFoundPage.vue"),
    },
    {
        path: "/:shortcode",
        // TODO improve the experience for users the 2nd time they use it and are already "logged in"
        name: "Invoice",
        component: () => import("../../views/LoginPage.vue"),
        meta: { checkShortcode: true },
    },
    {
        path: "/:shortcode/:form",
        // TODO improve the experience for users the 2nd time they use it and are already "logged in"
        name: "Form",
        component: () => import("../../views/LoginPage.vue"),
        meta: { checkShortcode: true, checkFormName: true },
    },
    {
        path: "/:shortcode/:form/supplementary-form",
        // TODO improve the experience for users the 2nd time they use it and are already "logged in"
        name: "SupplementaryForm",
        component: () => import("../../views/SupplementaryFormPage.vue"),
        meta: {
            requiresFormStructure: true,
            checkShortcode: true,
            checkFormName: true,
        },
    },
    /** Url for one time links */
    {
        path: "/:shortcode/:form/one-time-link/:id",
        name: "FormPayment",
        component: () => import("../../views/FormPayment.vue"),
        meta: {
            checkShortcode: true,
        },
    },
    {
        path: "/:shortcode/:form/payment",
        name: "PaymentMethod",
        component: () =>
            import("../../views/payment-methods/PaymentMethodPage.vue"),
        meta: {
            requiresPaymentMethods: true,
            checkShortcode: true,
            checkFormName: true,
        },
    },
    {
        path: "/:shortcode/:form/payment/transfer/:id",
        name: "TransferDetails",
        component: () =>
            import("../../views/providers/bank-transfer/TransferDetails.vue"),
        meta: {
            requiresPaymentMethods: true,
            checkShortcode: true,
            checkFormName: true,
            checkId: true,
        },
    },
    {
        path: "/:shortcode/:form/payment-methods-disabled",
        name: "DisabledPaymentMethods",
        component: () =>
            import("../../views/payment-methods/DisabledPaymentMethods.vue"),
        meta: {
            requiresPaymentMethods: true,
            checkShortcode: true,
            checkFormName: true,
        },
    },
    {
        path: "/auth0/login/callback",
        name: "Auth0Callback",
        component: () => import("../../views/auth/CallbackPage.vue"),
    },
    {
        path: "/auth0/logout/redirect",
        name: "Auth0Logout",
        component: () => import("../../views/auth/LogoutPage.vue"),
    },
    {
        path: "/:catchAll(.*)*",
        name: "404",
        component: () => import("../../views/default/NotFoundPage.vue"),
    },
    {
        path: "/opayo-3ds-iframe",
        name: "OpayoIframe",
        component: () => import("../../views/providers/cards/OpayoIframe.vue"),
    },
    {
        path: "/:shortcode/:form/payment/status/:id",
        name: "PaymentStatus",
        component: () => import("../../views/PaymentStatus.vue"),
        meta: { checkShortcode: true, checkFormName: true, checkId: true },
    },
    {
        path: "/unauthorised",
        name: "AccessDenied",
        component: () => import("../../views/default/AccessDeniedPage.vue"),
    },
    {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        component: () => import("../../views/default/TermsAndConditions.vue"),
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () => import("../../views/default/PrivacyPolicy.vue"),
    },
    {
        path: "/:shortcode/payment-request",
        name: "OldPaymentRequestLink",
        component: () => import("../../views/OldPaymentRequestLink.vue"),
        meta: {
            checkShortcode: true,
            checkFormName: false,
            checkId: false,
            requiresRedirectToNewLink: true,
        },
    },
    {
        path: "/reeds",
        name: "ReedsLandingPage",
        component: () => import("../../views/static/ReedsLandingPage.vue"),
        meta: { checkShortcode: false, checkFormName: false, checkId: false },
    },
    // E-mandates
    {
        path: "/:shortcode/e-mandates/:sun",
        name: routeNames.E_MANDATES_LANDING_PAGE,
        component: () => import("@/views/LoginPage.vue"),
        meta: { checkShortcode: true, checkFormName: false, requiresSun: true },
    },
    {
        path: "/:shortcode/e-mandates/:sun/form",
        name: routeNames.E_MANDATES_FORM,
        component: () => import("@/views/EMandatesForm.vue"),
        meta: { checkShortcode: true, checkFormName: false, requiresSun: true },
    },
    {
        path: "/:shortcode/e-mandates/:sun/confirmation",
        name: routeNames.E_MANDATES_CONFIRMATION,
        component: () => import("@/views/EMandatesConfirmation.vue"),
        meta: { checkShortcode: true, checkFormName: false, requiresSun: true },
    },
];

const routes = mainRoutes;

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/**
 * The ts-ignore comment below was added because on build the compiler will throw this
 * typescript error: TS6133: 'from' is declared but its value is never read.
 * But the router plugin requires all 3 parameters to be passed in.
 *  */
// @ts-ignore
router.beforeEach((to, from, next) => {
    const pStore = usePiniaStore();
    const { user, isAuthenticated } = useAuth0();

    /** Gets the meta properties associated to the route */
    const requiresFormStructure = to.matched.some(
        (x) => x.meta.requiresFormStructure,
    );
    const requiresPaymentMethods = to.matched.some(
        (x) => x.meta.requiresPaymentMethods,
    );
    const requiresShortcodeParam = to.matched.some(
        (x) => x.meta.checkShortcode,
    );
    const requiresFormNameParam = to.matched.some((x) => x.meta.checkFormName);
    const requiresIdParam = to.matched.some((x) => x.meta.checkId);
    const requiresRedirectToNewLink = to.matched.some(
        (x) => x.meta.requiresRedirectToNewLink,
    );
    const requiresSun = to.matched.some((x) => x.meta.requiresSun);

    /**
     * Redirects to 404 if the route requiresShortcodeParam and the
     * shortcode param is null or undefined
     */
    if (requiresShortcodeParam) {
        if (["null", "undefined"].includes(String(to.params.shortcode))) {
            next("/");
            return;
        }
    }

    /**
     * Redirects to 404 if the route requiresFormNameParam and the
     * form name param is null or undefined
     */
    if (requiresFormNameParam) {
        if (["null", "undefined"].includes(String(to.params.form))) {
            next("/");
            return;
        }
    }

    /**
     * Redirects to 404 if the route requiresIdParam and the
     * id param is null or undefined
     */
    if (requiresIdParam) {
        if (["null", "undefined"].includes(String(to.params.id))) {
            next("/");
            return;
        }
    }

    /**
     * Redirects to the first page if the route requiresFormStructure and
     * the form structure in the session is null
     */
    if (requiresFormStructure) {
        if (pStore.getFormStructure === null && from.name !== "Form") {
            next(`/${to.params.shortcode}/${to.params.form}`);
            return;
        }
    }

    /**
     * Redirects to the first page if the route requiresPaymentMethods and
     * the payment method list in the session is null
     */
    if (requiresPaymentMethods) {
        if (pStore.getPaymentMethods === null && from.name !== "Form") {
            next(`/${to.params.shortcode}/${to.params.form}`);
            return;
        }
    }

    /**
     * Redirects to the form page if the route requiresRedirectToNewLink and
     * it adds the payment_reference param as the form name
     */
    if (requiresRedirectToNewLink) {
        next(`/${to.params.shortcode}/${to.query.payment_reference}`);
        return;
    }

    /**
     * Redirects to 404 if the route requiresSun and the
     * sun param is null or undefined
     */
    if (requiresSun) {
        if (["null", "undefined"].includes(String(to.params.sun))) {
            next("/");
            return;
        }
    }

    if (to.path === "/auth0/login/callback") {
        /** When the user is redirected back to our app from Auth0, we
         * check if the authentication was successful. If yes, we save the
         * user information in the session storage and redirect to the
         * supplementary form page. If not, we redirect the user to the
         * login page based on the type of payment they initiated the journey
         * with.
         */
        setTimeout(function () {
            if (isAuthenticated.value) {
                pStore.setEmailAddress(
                    (user.value ? user.value.email : "") as string,
                );
                pStore.setUser({
                    email: (user.value ? user.value.email : "") as string,
                    name: (user.value ? user.value.name : "") as string,
                    emailVerified: (user.value
                        ? user.value.email_verified
                        : false) as boolean,
                    sub: (user.value ? user.value.user_role : "") as string,
                    picture: (user.value ? user.value.picture : "") as string,
                });

                if (pStore.getOrigin === routeNames.E_MANDATES_LANDING_PAGE) {
                    next(
                        `/${pStore.getShortcode}/e-mandates/${pStore.getSun}/form`,
                    );
                } else {
                    next(
                        `/${pStore.getShortcode}/${pStore.getFormName}/supplementary-form`,
                    );
                }
            } else {
                if (pStore.getOrigin === routeNames.E_MANDATES_LANDING_PAGE) {
                    next(`/${pStore.getShortcode}/e-mandates/${pStore.getSun}`);
                } else if (pStore.getFormName === "invoice") {
                    next(`/${pStore.getShortcode}`);
                } else {
                    next(`/${pStore.getShortcode}/${pStore.getFormName}`);
                }
            }
        }, 1000);
    } else if (to.path === "/auth0/logout/redirect") {
        /**
         * On logout we redirect the user to the to the login page corresponding
         * to the type of payment they initiated their journey with and clear the
         * user data.
         */
        pStore.setEmailAddress(null);
        pStore.setUser(null);

        if (pStore.getOrigin === routeNames.E_MANDATES_LANDING_PAGE) {
            next(`/${pStore.getShortcode}/e-mandates/${pStore.getSun}`);
        } else {
            const redirectPath =
                pStore.getFormName === "invoice"
                    ? `/${pStore.getShortcode}`
                    : `/${pStore.getShortcode}/${pStore.getFormName}`;
            next(redirectPath);
        }
    } else {
        next();
    }
});

export const redirectTo404 = () => {
    router.push({ name: "404" });
};

export const redirectToAccessDenied = () => {
    router.push({ name: "AccessDenied" });
};

export const redirectToPaymentStatus = (
    shortcode: string,
    formName: string,
    id: string,
) => {
    router.push({
        name: "PaymentStatus",
        params: {
            shortcode: shortcode,
            form: formName,
            id: id,
        },
    });
};

export const redirectToPath = (path: string) => {
    router.push(path);
};

export const redirectToRoute = (route: any) => {
    const existingState = { ...history.state };

    router.replace({
        ...route,
        state: existingState,
    });
};

export default router;
